import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'shards-react'

const IMG_PREFIX = 'https://www.quintoandar.com.br/img/xxl/'

const ImageGallery = ({ images }) => (
  <Container fluid>
    <Row>
      {images.map(imageId => (
        <Col xs={4}>
          <img
            alt="Imóvel"
            style={{ width: '100%', height: 'auto' }}
            src={`${IMG_PREFIX}${imageId}`}
          />
        </Col>
      ))}
    </Row>
  </Container>
)

ImageGallery.propTypes = {
  images: PropTypes.array.isRequired,
}

ImageGallery.defaultProps = {}

export default ImageGallery
